<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title style="float: left">Cargas Registros</v-card-title>
      <CButton
        color="primary"
        class="px-4"
        style="float: right"
        @click="crear()"
        >+ Nuevo</CButton
      >

      <v-card-text>
        <filters
          :campos="filterFields"
          :itemsKeys="{
            text: 'name',
            value: 'id',
          }"
          :eventName="'fetchItemsEvent'"
        ></filters>
        <div class="text-center" v-if="loading">
          <div class="spinner-border" role="status"></div>
        </div>
        <CDataTable
          v-if="!loading"
          hover
          striped
          :items="items"
          :fields="fields"
          :items-per-page="50"
          :active-page="activePage"
          :pagination="{ doubleArrows: false, align: 'center' }"
          @page-change="pageChange"
        >
        <template #created_at="data">
            <td>
              {{ obtenerFechaCorta(data.item.created_at) }}
            </td>
          </template>

          <template #activo="data">
            <td>
               &nbsp;<span
                v-if="data.item.estado == 'Pendiente'"
                class="spinner-border spinner-border-sm"
                role="status"
              ></span>
              <CBadge
                v-if="data.item.estado != 'Pendiente'"
                :color="data.item.estado == 'Procesado' ? 'success' : 'danger'"
                v-c-tooltip.hover.click="hoverImagenes(data.item)"
                >
                {{ data.item.estado }}
              </CBadge>
            </td>
          </template>
          <template #imagenes="data">
            <td>
              <small style="line-height: 9px; white-space: nowrap;">
                Cargadas: {{data.item.imagenes_cargadas}}<br>
                Sustituidas: {{data.item.imagenes_sustituidas}}<br>
                No encontradas en BD: {{data.item.imagenes_no_encontradas}}<br>
                Archivos sin el estándar: {{data.item.imagenes_no_estandar}}<br>
              </small>
            </td>
          </template>
        </CDataTable>
      </v-card-text>
    </v-card>
    <br><br>
  </div>
</template>

<script>
import registroFotograficoService from "@/services/registroFotografico.service";
import Filters from "@/components/filters";
import { mapState } from "vuex";
import EventBus from "@/common/EventBus";

export default {
  name: "Layout",
  data() {
    return {
      items: [],
      fields: [
        { key: "id", label: "Código" },
        { key: "created_at", label: "Fecha" },
        { key: "archivo_nombre", label: "Archivo asociado al cargue", _classes: "font-weight-bold" },
        { key: "nombre_zip", label: "ZIP" },
        { key: "imagenes", label: "Resultado carga" },
        { key: "activo", label: "Estado" }
      ],
      filterFields: [
        { value: "id", label: "Código" },
        { value: "nombre_zip", label: "Nombre Archivo ZIP" },
        {
          value: "estado",
          label: "Estado",
          options: [
            { id: "Procesado", name: "Procesado" },
            { id: "Pendiente", name: "Pendiente" },
            { id: "Error", name: "Error" }
          ],
        },
      ],
      activePage: 1,
      loading: false,
      message: "",
    };
  },
  components: {
    Filters,
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  computed: {
    ...mapState(["permissions"]),
  },
  mounted() {
    if (!this.permissions.includes("Repositorio Fotográfico - Cargar"))
      this.$router.push("/dashboard");
    this.fetchItems("");
    EventBus.on("fetchItemsEvent", (cadenaBusqueda) => {
      this.fetchItems(cadenaBusqueda);
    });
  },
  methods: {
    fetchItems(cadenaBusqueda) {
      this.loading = true;
      registroFotograficoService.getItems(cadenaBusqueda).then(
        (response) => {
          this.items = response.data;
          this.items = this.items.slice(0).reverse();
          this.items = this.items.map(function f(x) {
            return { ...x, archivo_nombre: x.archivo_layout.nombre };
          });
          console.log(response.data);
          this.loading = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    hoverImagenes(item){
      return ''
      return `Cargadas: ${item.imagenes_cargadas}; 
                Sustituidas: ${item.imagenes_sustituidas}; 
                No encontradas en BD: ${item.imagenes_no_encontradas}; 
                Archivos sin el estándar: ${item.imagenes_no_estandar}`
    },
    obtenerFechaCorta(fecha) {
      const dateObj = new Date(fecha);
      const opciones = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      //const fechaCorta = dateObj.toLocaleDateString("es-ES", opciones) + " " + dateObj.toLocaleTimeString("es-ES", opciones);
      const fechaCorta = dateObj.toLocaleDateString("es-ES", opciones);
      return fechaCorta;
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
     crear() {
      this.$router.push({ path: `cargas/nueva-carga` });
    },
  },
};
</script>
